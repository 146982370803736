<!--
 * @Author: your name
 * @Date: 2022-04-27 15:24:07
 * @LastEditTime: 2022-05-25 14:29:28
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \newwifisvn\src\views\termsService\index.vue
-->
<template>
  <div>
    <h5 class="h4">锐WiFi用户使用服务协议</h5>
    <p>一、特别提示</p>
    <p>在此特别提醒您（用户）在注册成为锐WiFi用户之前，请认真阅读本《锐WiFi用户使用服务协议》（以下简称“协议”），确保您充分理解本协议中各条款。请您审慎阅读并选择接受或不接受本协议。您同意并点击确认本协议条款且完成注册程序后，才能成为锐WiFi的正式注册用户，并享受锐WiFi的各类服务。您的注册、登录、使用等行为将视为对本协议的接受，并同意接受本协议各项条款的约束。若您不同意本协议，或对本协议中的条款存在任何疑问，请您立即停止锐WiFi用户注册程序，并可以选择不使用本应用服务。
    本协议约定锐WiFi与用户之间关于“锐WiFi”服务（以下简称“服务”）的权利义务。“用户”是指注册、登录、使用本服务的个人、单位。本协议可由锐WiFi随时更新，更新后的协议条款一旦公布即代替原来的协议条款，不再另行通知，用户可在本APP中查阅最新版协议条款。在修改协议条款后，如果用户不接受修改后的条款，请立即停止使用锐WiFi提供的服务，用户继续使用锐WiFi提供的服务将被视为接受修改后的协议。</p>
    <p>二、账号注册</p>
    <p>1、用户在使用本服务前需要注册一个“锐WiFi”账号。“锐WiFi”账号应当使用手机号码绑定注册，请用户使用尚未与“锐WiFi”账号绑定的手机号码，以及未被锐WiFi根据本协议封禁的手机号码注册“锐WiFi”账号。锐WiFi可以根据用户需求或产品需要对账号注册和绑定的方式进行变更，而无须事先通知用户。</p>
    <p>2、如果注册申请者有被锐WiFi封禁的先例或涉嫌虚假注册及滥用他人名义注册，及其他不能得到许可的理由，将拒绝其注册申请。</p>
    <p>3、鉴于“锐WiFi”账号的绑定注册方式，您同意锐WiFi在注册时将允许您的手机号码及手机设备识别码等信息用于注册。</p>
    <p>4、在用户注册及使用本服务时，锐WiFi需要搜集能识别用户身份的个人信息以便锐WiFi可以在必要时联系用户，或为用户提供更好的使用体验。锐WiFi搜集的信息包括但不限于用户的手机号、微信名称。</p>
    <p>三、账户安全</p>
    <p>1、用户一旦注册成功，成为锐WiFi的用户，将获得一个锐WiFi账户，并有权利使用自己的账号及验证码随时进行登录。</p>
    <p>2、用户对账户的安全负全部责任，同时对以其账户进行的所有活动和事件负全责。</p>
    <p>3、用户不得以任何形式擅自转让或授权他人使用自己的锐WiFi账户。</p>
    <p>4、用户切勿将账户信息告知他人，因信息保管不善而造成的所有损失由用户自行承担。</p>
    <p>5、如果用户泄露了账户信息，有可能导致不利的法律后果，因此不管任何原因导致用户的账户安全受到威胁，应该立即和锐WiFi客服人员取得联系，否则后果自负。</p>
    <p>四、服务内容</p>
    <p>锐WiFi是由河南云锐网络技术有限公司提供的WiFi设备管理平台，用户通过锐WiFi管理和维护绑定的WiFi设备。</p>
    <p>锐WiFi有权随时审核或删除用户发布/传播的涉嫌违法或违反社会主义精神文明，或者被锐WiFi认为不妥当的内容(包括但不限于文字、语音、图片、视频图文等)。</p>
    <p>3、所有发给用户的通告及其他消息都可通过APP或者用户所提供的联系方式发送。</p>
    <p>五、免责与赔偿声明</p>
    <p>1、若锐WiFi已经明示其服务提供方式发生变更并提醒用户应当注意事项，用户未按要求操作所产生的一切后果由用户自行承担。</p>
    <p>2、用户明确同意其使用锐WiFi所存在的风险将完全由其自己承担，因其使用锐WiFi而产生的一切后果也由其自己承担。</p>
    <p>3、用户同意保障和维护锐WiFi及其他用户的利益，由于用户在使用锐WiFi有违法、不真实、不正当、侵犯第三方合法权益的行为，或用户违反任何条款而给锐WiFi及任何其他第三方造成损失，用户同意承担由此造成的损害赔偿责任。</p>
    <p>六、权利限制</p>
    <p>1、禁⽌反向⼯程、反向编译和反向汇编：⽤户不得对本产品进⾏反向⼯程(Reverse Engineer)、反向编译(Decompile)或反向汇编(Disassemble)，同时不得改动编译在程序⽂件内部的任 何资源。除法律、法规明⽂规定允许上述活动外，⽤户必须遵守此协议限制。</p>
    <p>2、组件分割：本产品是作为⼀个单⼀产品⽽被授予许可使⽤, ⽤户不得将各个部分分开⽤于任何⽬的。 </p>
    <p>3、个别授权：如需进⾏商业性的销售、复制、分发，包括但不限于软件销售、预装、捆绑等，必须获得锐WiFi的授权和许可。</p>
    <p>4、保留权利：本声明及《用户协议》中未明⽰授权的其他⼀切权利仍归锐WiFi所有。</p>
    <p>七、用户声明与保证</p>
    <p>1、用户承诺其为具有完全民事行为能力的民事主体，且具有达成交易履行其义务的能力。</p>
    <p>2、用户有义务在注册时提供自己的真实资料，并保证诸如手机号码、姓名、所在地区等内容的有效性及安全性，保证锐WiFi工作人员可以通过上述联系方式与用户取得联系。同时，用户也有义务在相关资料实际变更时及时更新有关注册资料。</p>
    <p>3、用户通过使用锐WiFi的过程中所制作、上载、复制、发布、传播的任何內容，包括但不限于账号头像、名称、用户说明等注册信息及认证资料，或文字、语音、图片、视频、图文等发送、回复和相关链接页面，以及其他使用账号或本服务所产生的内容，不得违反国家相关法律制度，包括但不限于如下原则：
    (1)违反宪法所确定的基本原则的；
    (2)危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；
    (3)损害国家荣誉和利益的；
    (4)煽动民族仇恨、民族歧视，破坏民族团结的；
    (5)破坏国家宗教政策，宣扬邪教和封建迷信的；
    (6)散布谣言，扰乱社公秩序，破坏社会稳定的；
    (7)散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；
    (8)侮辱或者诽谤他人，侵害他人合法权益的；
    (9)含有法律、行政法规禁止的其他内容的。</p>
    <p>4、用户不得利用“锐WiFi”账号或本服务制作、上载、复制、发布、传播下干扰“锐WiFi”的正常运营，以及侵犯其他用户或第三方合法权益的内容：(1)含有任何性或性暗示的；
    (2)含有辱骂、恐吓、威胁内容的；
    (3)含有骚扰、垃圾广告、恶意信息、诱骗信息的；
    (4)涉及他人隐私、个人信息或资料的；
    (5)侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的；
    (6)含有其他干扰本服务正常运营和侵犯其他用户或第三方合法权益内容的信息。</p>
    <p>八、服务的终止</p>
    <p>1、在下列情况下，锐WiFi有权终止向用户提供服务；
    （1）在用户违反本服务协议相关规定时，锐WiFi有权终止向该用户提供服务；如该用户再一次直接或间接或以他人名义注册为用户的，一经发现，锐WiFi有权直接单方面终止向该用户提供服务；
    （2）如锐WiFi通过用户提供的信息与用户联系时，发现用户在注册时填写的联系方式已不存在或无法接通，锐WiFi以其它联系方式通知用户更改，而用户在三个工作日内仍未能提供新的联系方式，锐WiFi有权终止向该用户提供服务；
    （3）用户不得通过程序或人工方式进行刷量或作弊等非正常操作行为，若发现用户有类似行为，锐WiFi将立即终止服务，并有权扣留账户内金额；
    （4）一旦锐WiFi发现用户提供的数据或信息中含有虚假内容，锐WiFi有权随时终止向该用户提供服务；
    （5）本服务条款终止或更新时，用户明示不愿接受新的服务条款；
    （6）以及其他锐WiFi认为需要终止服务的情况。</p>
    <p>2、服务终止后，锐WiFi没有义务为用户保留原账号中或与之相关的任何信息，或转发任何未曾阅读或发送的信息给用户或第三方。</p>
    <p>3、用户理解并同意，即便在本协议终止及用户的服务被终止后，锐WiFi仍有权：
    （1）继续保存您的用户信息；
    （2）继续向用户主张在其使用平台服务期间因违反法律法规、本协议及平台规则而应承担的责任。</p>
    <p>九、服务的变更、中断</p>
    <p>1、鉴于网络服务的特殊性，用户需同意锐WiFi会变更、中断部分或全部的网络服务，并删除（不再保存）用户在使用过程中提交的任何资料，而无需通知用户，也无需对任何用户或任何第三方承担任何责任。 </p>
    <p>2、锐WiFi需要定期或不定期地对提供网络服务的平台进行检测或者更新，如因此类情况而造成网络服务在合理时间内的中断，锐WiFi无需为此承担任何责任。</p>
    <p>十、服务条款修改</p>
    <p>1、锐WiFi有权随时修改本服务条款的任何内容，一旦本服务条款的任何内容发生变动，锐WiFi将会通过适当方式向用户提示修改内容。</p>
    <p>2、如果不同意锐WiFi对本服务条款所做的修改，用户有权停止使用网络服务。</p>
    <p>3、如果用户继续使用网络服务，则视为用户接受锐WiFi对本服务条款所做的修改。</p>
    <p>十一、其他</p>
    <p>1、锐WiFi郑重提醒用户注意本协议中的各项条款，请用户仔细阅读，自主考虑风险。未成年人应在法定监护人的陪同下阅读本协议。</p>
    <p>2、本协议的效力、解释及纠纷的解决，适用于中华人民共和国法律。若用户和锐WiFi之间发生任何纠纷或争议，首先应友好协解决，协商不成的，用户同意将纠纷或争议提交有管辖权的人民法院管辖。</p>
    <p>3、本协议的任何条款无论因何种原因无效或不具可执行性，其余条款仍有效，对双方具有约束力。</p>
    <p>4、本协议最终解释权归河南云锐网络技术有限公司所有，并且保留一切解释和修改的权力。</p>
    <p>5、本协议自2022年4月24日起适用。</p>
  </div>
</template>
<script>
export default {
  data() {
    return{

    }
  },
}
</script>
<style lang="less" scoped>
.h4{
  text-align: center;
  font-size: 14px;
  padding-top: 20px;
  margin-bottom: 10px;
}
</style>